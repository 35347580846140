import '../../styles/pages/spring-summer-2024/finding-my-leadership-voice.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2024/finding-my-leadership-voice/finding-my-leadership-voice-hero-1920x1080.jpg';
import socialBanner from "../../images/spring-summer-2024/social-banners/finding-my-leadership-voice-social-banners-1200x630.jpg";
import titelGraphic from "../../images/spring-summer-2024/finding-my-leadership-voice/finding-my-leadership-voice-title-380x245.svg";
import ctaBanner from '../../images/spring-summer-2024/finding-my-leadership-voice/finding-my-leadership-voice-cta-banner-1920x515.jpg';

import findingmyleadershipvoiceIMG1 from '../../images/spring-summer-2024/finding-my-leadership-voice/finding-my-leadership-voice-image-1-533x800.jpg';
import findingmyleadershipvoiceIMG2 from '../../images/spring-summer-2024/finding-my-leadership-voice/finding-my-leadership-voice-image-1-800x533.jpg';
import findingmyleadershipvoiceIMG3 from '../../images/spring-summer-2024/finding-my-leadership-voice/finding-my-leadership-voice-image-2-800x533.jpg';


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Center for Leadership Empowers Accounting Student';
    var pageDesc = 'With support from Cal State Fullerton’s Center for Leadership, accounting alumna Selena-Andria Liu lands a dream job at Big Four accounting firm Ernst & Young.';
    var slug = 'finding-my-leadership-voice';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      // {
      //   src: findingherleadershipvoiceIMG1,
      //   width: 533,
      //   height: 800,
      //   alt: '',
      //   title: ''
      // },
      // {
      //   src: findingherleadershipvoiceIMG2,
      //   width: 800,
      //   height: 553,
      //   alt: '',
      //   title: ''
      // },
      // {
      //   src: findingherleadershipvoiceIMG3,
      //   width: 800,
      //   height: 553,
      //   alt: '',
      //   title: ''
      // },
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='Finding My Leadership Voice' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">With support from CSUF’s Center for Leadership, an accounting alumna lands a dream job at Big Four accounting firm Ernst & Young.
</p>
            <span className="animated fadeInDown delay-1s byline">By Selena-Andria Liu / Photos by Matt Gush</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

          <p><span className="intro-text">As a freshman in college,</span> it was unimaginable to me that I would one day be shaking hands with top business executives and leaders in Southern California.</p>
<p>I joined the Giles-O&rsquo;Malley Center for Leadership at Cal State Fullerton during my first semester and it was one of the best decisions I made. Through the center, I discovered a friendly and optimistic community of scholars who helped me gain the confidence to become a leader in the organization.</p>
<p>For four years, I participated in several programs through the Center for Leadership. During my sophomore year, I became the vice president of development and helped lead student programs, including training on unconscious bias and diversity, equity and inclusion. As a senior, I took on the role of vice president of community outreach, where I led and coordinated multiple volunteer projects to support local communities and nonprofits. Being part of the center gave me opportunities to meet with and learn from executives like networking coach Terry Goldfarb, leadership coach Scharrell Jackson and Ernst &amp; Young managing partner Kim Letch.</p>

<figure className='align-right large align-outside'>
            <img src={findingmyleadershipvoiceIMG1} alt="" />
  <figcaption>Selena-Andria Liu, CSUF alumna and assurance associate at Ernst & Young</figcaption>
</figure>

<p>I&rsquo;m thankful for every person I met during my four years at Cal State Fullerton. The numerous communities I was a part of gave me the time and space to grow being true to myself. Whether it was laughing and catching up with friends in the College of Business and Economics lobby or the Titan Student Union, attending Center for Leadership and community service events, or studying for finals in Pollak Library &mdash; I cherish all of these memories.</p>
<p>I chose to attend CSUF not only for its well-known accounting program but also because of its handicap-accessible environment. As a person with cerebral palsy, it was important to me to attend a walkable campus. The farthest buildings were a manageable journey.</p>
<p>As a business administration-accounting student, my two favorite classes were Cost Accounting and Intermediate Microeconomics. Zhejia Ling, assistant professor of accounting, and Francis Mummery, lecturer in economics, turned classes I was required to take into classes I was excited to take.</p>
<p>Heading into the summer of 2022, I applied for and was offered an internship at Ernst &amp; Young, one of the Big Four accounting firms. Following my graduation in 2023, I joined the company as an assurance associate, applying my communication and analytical skills to provide high-quality audit services to clients.</p>

<blockquote>
  <p><em>As a freshman in college, it was unimaginable to me that I would one day be shaking hands with top business executives and leaders in Southern California.</em>&nbsp;”</p>
              <cite>Selena-Andria Liu ’23</cite>
</blockquote>

<p>My responsibilities with EY include working with the engagement teams to perform analytical testing, tests of controls and details, and more for clients. I am passionate about using my accounting knowledge and skills to contribute to EY&rsquo;s mission of building a better working world. My goal is to continuously develop my professional and personal competencies, and to make a positive impact on society.</p>
<p>While at EY, I will begin my journey toward becoming a certified public accountant. In the future, I am considering attending law school and combining that knowledge with my business and accounting experience. I have a passion for political science, the justice system and civilian rights, which I also developed at Cal State Fullerton.</p>
<p>Beyond my business classes, I enjoyed taking an International Law class during my last semester. David Traven, associate professor of political science, was passionate about the topic and I learned a lot about the laws that apply to different countries. As someone who had only taken one other political science class, Professor Traven made complex concepts clear enough for me to understand.</p>
		    <figure className='align-wide'>
            <img src={findingmyleadershipvoiceIMG2} alt="" />
            <figcaption>Selena-Andria Liu ’23</figcaption>
</figure>
<p>Although students&rsquo; backgrounds, interests or experiences are diverse, I can offer some general advice for future students:</p>

<ol>
  <li>
    <p>Enroll in a variety of classes to explore potential interests and careers.</p>
  </li>
  <li>
    <p>Put yourself out there. (I am still working on this myself.) During your time at college, check out the clubs and organizations that pique your interest. This will lead to learning opportunities and meeting many new friends.</p>
  </li>
  <li>
    <p>Take advantage of opportunities to network with professionals in the industries you are interested in. They will give you insight into potential careers and possibly offer the chance to shadow them.</p>
  </li>
  <li>
    <p>Ask before you act. This is especially important when working with people with disabilities. People with disabilities know the best way others can help them.</p>
  </li>
  <li>
    <p>Finally, embrace yourself and your differences. I&rsquo;m well aware this is easier said than done, but your differences make you <em>you</em>.</p>
  </li>
</ol>
<p><strong><em>Selena-Andria Liu completed her bachelor&rsquo;s degree in business administration-accounting at Cal State Fullerton in 2023. Through the university&rsquo;s Giles-O&rsquo;Malley Center for Leadership, she participated in cutting-edge curriculum and leadership development experiences &mdash; ultimately becoming a leader in the organization. Following graduation, Liu joined Ernst &amp; Young, the second largest accounting firm in Orange County, as an assurance associate.</em></strong></p>
<figure className='align-wide'>
            <img src={findingmyleadershipvoiceIMG3} alt="" />
  <figcaption>Rendering of Landmark Hall</figcaption>
</figure>
<h3>A New Building for the Center for Leadership</h3>
<p>The Giles-O&rsquo;Malley Center for Leadership &mdash; which aims to develop the leadership skills&nbsp; of students and the Orange County community through education, training and outreach &mdash; will break ground on a state-of-the art building adjacent to Cal State Fullerton&rsquo;s <a href="https://www.fullerton.edu/business-economics/" title="College of Business and Economics" target="_blank">College of Business and Economics</a> in summer 2024.</p>
<p>The 21,392-square-foot, three-story building<strong id="docs-internal-guid-558fb28a-7fff-58eb-cf95-a8cf9242782c">, </strong>called <a href="https://business.fullerton.edu/go/landmark" title="Landmark Hall" target="_blank">Landmark Hall</a> will serve as a hub of study and exploration on the topic of leadership not only for CSUF and its students, but for businesses across Orange County and Southern California.</p>
<p>Open to all students interested in the idea of leadership,  500 students across all majors annually access programs and activities through the center. Programs like the Leadership Scholars and Emerging Titan Buddy’s Program help students build essential leadership skills, receive executive mentoring and establish a network with Orange County business leaders.</p>
<p>The Center for Leadership also distributes $50,000 in student scholarships each year and produces &ldquo;The Leadership Voice,&rdquo; a television program that is available online and via cable television.</p>
<p>In the community, the center delivers leadership development on-site to businesses through its Bringing Learning to Work program. Companies can host CSUF professors for a series of lunch-and-learn programs customized to the challenges facing their organizations or industries.</p>
<p>The Center for Leadership is led by Jay Barbuto, CSUF professor of management and renowned researcher in servant leadership, emotional intelligence and work motivation. The center is named in honor of longtime university supporters Terry Giles &rsquo;70 (B.A. speech communication) and his wife, Kalli O&rsquo;Malley, whose generosity continues to advance its mission. <span className="end-mark" /></p>


</div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the Center for Leadership</span><br />
              <a href="https://give.fullerton.edu/cfs" target='_blank' rel='noreferrer'>give.fullerton.edu/leadership</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 